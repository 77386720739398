import {MenuView} from "../dto/MenuItemData";

const requestMapping = "api"

class Api {
  token: string | undefined;

  constructor(token: string | undefined) {
    this.token = token;
  }

  private get(uri: string): Promise<any> {
    if (!this.token) throw Error("NO TOKEN")
    return fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/${requestMapping}/${uri}`, {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + this.token, 'Content-Type': 'text/markdown'}
    });
  }

  // public getPublicText(category: string, filename: string): Promise<string> {
  //   return this.get(`texts/docs/${category}/${filename}`).then(res => res.text())
  // }

  public async getMenu(): Promise<MenuView[]> {
    return this.get("menus").then(res => {
      if (!res.ok) return Promise.reject(res)
      return res.json()
    }).catch(err => console.log(err))
  }

//   private post(uri: string, body: any): Promise<any> {
//     if (!this.token) throw "NO TOKEN"
//     return fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/` + uri, {
//       method: 'post',
//       headers: new Headers({
//         'Authorization': 'Bearer ' + this.token,
//         'Content-Type': 'Content-Type": "application/json'
//       }),
//       body: JSON.stringify(body)
//     }).then(res => res.json());
//   }
}

export const fetchDocs = async (token: string | undefined, category: string, subCategory: string, filename: string): Promise<string> => {
  if (!token) throw Error("NO TOKEN")
  return fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/${requestMapping}/docs/${category}/${subCategory}/${filename}`, {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'text/markdown'}
  }).then(res => {
    if (!res.ok) return Promise.reject(res)
    return res.text()
  }).catch(err => {
    console.log(err)
    throw new Error(err)
  })
}

export const fetchMenuView = async (token: string | undefined): Promise<any> => {
  if (!token) throw new Error("NO TOKEN")
  return fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/${requestMapping}/menus`, {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + token}
  }).then(res => {
    if (res.ok) {
      return res.json()
    }
    return Promise.reject(res)
  }).catch(err => console.log(err))
}

export {
  Api
}
