import React, {createContext} from "react";
import {useQuery} from "@tanstack/react-query";
import {MenuView, MenuViewDefaultData} from "../dto/MenuItemData";
import {useKeycloak} from "@react-keycloak/web";
import CircularProgress from "@mui/material/CircularProgress";
import {fetchMenuView} from "../api/Api";

export const RootModelContext = createContext<MenuView[]>([] as MenuView[]);

type Props = {
  children: JSX.Element;
};

export const RootModelProvider = ({children}: Props) => {
  const {keycloak} = useKeycloak();
  const token = keycloak && keycloak.token;

  const {data, isLoading} = useQuery(["FETCH_ROOT"], () => {
    return fetchMenuView(token)
  })

  if (isLoading) return <CircularProgress/>

  return (
      <RootModelContext.Provider value={data}>
        {children}
      </RootModelContext.Provider>
  );
};

