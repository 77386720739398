import React from 'react';
import {ReactKeycloakProvider} from '@react-keycloak/web'
import keycloak from './keycloak'
import Root from "./root/Root";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } })

function App() {
  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <QueryClientProvider client={queryClient}>
        <Root/>
      </QueryClientProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
