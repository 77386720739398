import React from 'react';
import {useParams} from "react-router-dom";
import PubText from "../components/texts/PubText";
import {useKeycloak} from "@react-keycloak/web";

export default function PubMain() {
  const {category, filename, subCategory} = useParams() as { category: string, subCategory: string, filename: string };
  const {keycloak} = useKeycloak();

  if (!keycloak.hasRealmRole("employee") && category === "employee") return null;
  if (!keycloak.hasRealmRole("lia") && category === "tech") return null;

  return <PubText category={category} subCategory={subCategory} filename={filename}/>
}
