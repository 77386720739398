import React, {useState} from 'react';
import {useKeycloak} from "@react-keycloak/web";
import {BrowserRouter as Router} from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import {MainRoutes} from "./MainRoutes";
import SideMenu from "./SideMenu";
import Header from './Header';
import {Box, CssBaseline, Grid} from '@mui/material';
import Toolbar from "@mui/material/Toolbar";
import {RootModelProvider} from "./RootModelContext";

export default function Root() {
  const {keycloak, initialized} = useKeycloak();
  const [mobileViewOpenSideMenu, setMobileViewOpenSideMenu] = useState(false);
  const drawerWidth = 250

  if (!initialized)
    return (
      <div>
        <h1>Initializing...</h1>
        <CircularProgress/>
      </div>
    )

  if (!keycloak.authenticated) {
    keycloak.login()
    return null;
  }
  const toggleMobileViewSideMenu = () => setMobileViewOpenSideMenu(!mobileViewOpenSideMenu)

  return (
    // <ResponsiveViewProvider>
    <RootModelProvider>

      {/*<ApiProvider>*/}
      <Router>
        <CssBaseline/>
        <Box sx={{display: 'flex'}}>
          {/*<HeaderDeprecated/>*/}
          <Header onIconMenuClicked={toggleMobileViewSideMenu}/>
          {/*<Nav/>*/}
          <SideMenu mobileViewOpenSideMenu={mobileViewOpenSideMenu} onClose={toggleMobileViewSideMenu}/>
          <Box sx={{flexGrow: 1, p: 3, width: {xs: `calc(100% - ${drawerWidth}px)`}}}>
            <Grid visibility={"hidden"}><Toolbar/></Grid>
            <MainRoutes/>
          </Box>
        </Box>
        {/*<Footer/>*/}
      </Router>
      {/*</ApiProvider>*/}
    </RootModelProvider>

    // </ResponsiveViewProvider>
  )
}

