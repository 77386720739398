import React, {FC, useContext} from 'react';
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {useLocation, useNavigate} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import sensera from "../images/Sensera.png";
import {Link} from '@mui/material';
import {MenuView} from "../dto/MenuItemData";
import {RootModelContext} from "./RootModelContext";

interface I {
  onIconMenuClicked?: () => void
}

const Header: FC<I> = (props) => {
  const menuViews = useContext<MenuView[]>(RootModelContext);
  const {keycloak} = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation();

  const isUriInCategoryDirectory = (uri: string) => {
    const parts = location.pathname.split('/');
    if (parts.length <= 3) {
      const splitUrls = uri.split('/');
      if (splitUrls[0] === "") {
        return true
      }
    }
    const category = parts[2];
    const splitUrls = uri.split('/');
    const categoryCompare = splitUrls[splitUrls.length - 2];
    return category === categoryCompare;
  }

  return (
      <AppBar position="fixed" elevation={0} color={"inherit"} sx={{borderBottom: "solid lightgrey"}}>
        <Toolbar disableGutters>
          <Button>
            <img width="200px" height="100%" src={sensera} onClick={() => navigate("/", {replace: true})}
                 alt="Sensera LOGO"/>
          </Button>
          <IconButton onClick={props.onIconMenuClicked} sx={{mr: 'none', display: {md: 'none'}}}>
            <MenuIcon/>
          </IconButton>
          <Box
              sx={{
                ml: 5,
                display: {xs: 'none', sm: 'none', md: 'flex'},
                flexWrap: 'wrap',
                justifyContent: 'center',
                typography: 'body1',
                '& > :not(style) + :not(style)': {md: {marginLeft: 20}, lg: {marginLeft: 20}}
              }}
          >
            {menuViews &&
                menuViews.map((item) => {
                  if (
                      (item.visibility === "employee" && keycloak.hasRealmRole("employee")) ||
                      (item.visibility === "tech" && keycloak.hasRealmRole("lia")) ||
                      item.visibility === "public"
                  ) {
                    return (
                        <Link
                            key={item.root}
                            onClick={() => navigate(item.uri)}
                            sx={{
                              color: isUriInCategoryDirectory(item.uri) ? "greedy" : "gray",
                              '&:hover': {cursor: "pointer", color: "greedy"},
                            }}
                            fontWeight={"bold"}
                            textTransform={"capitalize"}
                            underline="none"
                        >
                          {item.root}
                        </Link>
                    );
                  }
                  return null;
                })
            }
          </Box>
          <Box flexGrow={1}/>
          <Grid item mr={3}>
            <Button variant={"outlined"} color={"success"} onClick={() => keycloak.logout()}>Logout</Button>
          </Grid>
        </Toolbar>
      </AppBar>
  )
};


export default Header;
