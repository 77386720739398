import React from "react";
import {Navigate, Route, Routes} from 'react-router-dom';
import {Main} from "./Main";
import PubMain from "../pub/PubMain";

export function MainRoutes() {
  return (
    <Routes>
      {/*<Route path="/pub/:name" element={<PubMain/>} />*/}
      {/*<Route path="/docs/backend/index.md" element={<PubMain/>} />*/}
      <Route path="/docs/:category/:subCategory/:filename" element={<PubMain/>}/>
      <Route path="/docs/:category/:filename" element={<PubMain/>}/>
      <Route path="/docs/:category/" element={<PubMain/>}/>
      <Route path="/" element={<Navigate to="/docs/index.md"/>} />

      {/*<Route path="/" element={<Main/>}/>*/}
    </Routes>
  )
}
