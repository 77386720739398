import React, {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  Collapse,
  Divider,
  FormControl, InputLabel,
  ListItemButton,
  ListItemIcon,
  ListItemText, Select,
  SelectChangeEvent
} from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import {MenuView, MenuViewDefaultData} from "../dto/MenuItemData";
import {RootModelContext} from "./RootModelContext";
import CircularProgress from "@mui/material/CircularProgress";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import MenuItem from "@mui/material/MenuItem";

interface Props {
  mobileViewOpenSideMenu?: boolean,
  onClose: () => void
}

const SideMenu = (props: Props) => {
  let theme = useTheme();
  const location = useLocation();
  let navigate = useNavigate();
  const toggleDrawerVariant = useMediaQuery(theme.breakpoints.down('md'));
  const drawerWidth = 250;
  const menuViews = useContext<MenuView[]>(RootModelContext);
  const [menuItems, setMenuItems] = useState<MenuView>(MenuViewDefaultData);

  const [open, setOpen] = useState(true);

  useEffect(() => {
    if(menuViews){
      const currentItem = menuViews.find(item => {
        const parts = location.pathname.split('/');
        const category = parts[2];
        const middle = item.uri.split('/');
        const categoryCompare = middle[middle.length - 2];
        return category === categoryCompare;
      });
      if (currentItem) {
        setMenuItems(currentItem);
      }
      if (!currentItem) {
        setMenuItems(menuViews[0]);
      }
    }

  }, [location.pathname]);

  const onClickListItemHandler = () => {
    if (!location.pathname.includes(menuItems.uri))
      navigate(menuItems.uri);
  };

  const handleClick = (uri: string) => {
    navigate(uri);
    // setOpen(!open);
  };

  const isUriInCategoryDirectory = (uri: string) => {
    const parts = location.pathname.split('/');
    if (parts.length <= 3) {
      return false;
    }
    if (parts.length >= 5) {
      const subCategory = parts[3];
      return uri.includes(subCategory)
    }
  }

  const selectDropdown = (e: SelectChangeEvent<unknown>) => {
    const findRootItem = menuViews.find(item => item.root === e.target.value);
    if (findRootItem)
      navigate(findRootItem.uri);
  };

  return (
    <Box sx={{width: {md: drawerWidth}, flexShrink: {sm: 0}}}>
      <Drawer
        open={props.mobileViewOpenSideMenu}
        onClose={props.onClose}
        variant={toggleDrawerVariant ? "temporary" : "permanent"}
        ModalProps={{keepMounted: true}}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: "nowrap",
          },
          '& > div': {marginTop: {xs: 0, md: 9.1}}
        }}
      >
        {menuItems.root === "empty" ? <CircularProgress/> : <>
          <Box sx={{display: {sm: "block", md: "none"}}} mt={1} p={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Välj Kategori</InputLabel>
              <Select
                label="Välj Kategori"
                value={menuItems.root}
                onChange={selectDropdown}
              >
                {menuViews && menuViews.map((item, index) => {
                  return <MenuItem value={item.root} key={index}>{item.root}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Box>
          <List>
            <ListItemButton
              onClick={() => onClickListItemHandler()}
              sx={{
                padding: 2, borderBottom: "2px solid purple",
                background: location.pathname.includes(menuItems.root) ? "lightgrey" : "white",
                '&:hover': {background: "lightgrey"}
              }}
            >
              <Typography variant={"subtitle1"} fontWeight={"bold"}>{menuItems.root}</Typography>
            </ListItemButton>
          </List>
        </>}
        {menuItems.uri !== "/docs/index.md" && menuItems.childrens && menuItems.childrens[0] ? <>
            <List>
              {menuItems.childrens && menuItems.childrens.map((child, index) => {
                return (
                  <Box key={child.uri}>
                    <ListItemButton onClick={() => handleClick(child.uri)}
                                    selected={location.pathname.includes(child.uri)}>
                      <ListItemText primary={child.item}/>
                      {isUriInCategoryDirectory(child.uri) ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                    <Collapse in={isUriInCategoryDirectory(child.uri)} timeout="auto" unmountOnExit>
                      {child.childrens && child.childrens.map((subItem, index) => {
                        return (
                          <List component="div" disablePadding key={subItem.uri}>
                            <ListItemButton sx={{pl: 4}} onClick={() => navigate(subItem.uri)}
                                            selected={location.pathname.includes(subItem.uri)}>
                              <ListItemText primary={subItem.item}/>
                            </ListItemButton>
                          </List>)
                      })}
                    </Collapse>
                  </Box>)
              })}
            </List>
          </>
          :
          <></>}
      </Drawer>
    </Box>
  )
}

export default SideMenu;
