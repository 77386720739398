export const MenuViewDefaultData: MenuView = {
  root: '',
  uri: '',
  visibility: '',
  childrens: [{item: '', uri: '', childrens: [{item: '', uri: '', visibility: ''}], visibility: ''}]
}

class MenuView {
  root: string
  uri: string
  visibility: string
  childrens: Directories[]

  constructor(root: string, uri: string, visibility: string, childrens: Directories[]) {
    this.root = root;
    this.uri = uri;
    this.visibility = visibility;
    this.childrens = childrens;
  }
}

class Directories {
  item: string
  uri: string
  childrens: File[]
  visibility: string

  constructor(item: string, uri: string, childrens: File[], visibility: string) {
    this.item = item;
    this.uri = uri;
    this.childrens = childrens;
    this.visibility = visibility;
  }
}

class File {
  item: string
  uri: string
  visibility: string

  constructor(item: string, uri: string, visibility: string) {
    this.item = item;
    this.uri = uri;
    this.visibility = visibility;
  }
}

export {
  MenuView,
  Directories,
  File
}
